/* ./src/index.css */
@import url("./fonts/stylesheet.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

input:checked + div svg {
  @apply block focus-within:sr-only !important;
}

.content-menu {
  height: calc(100vh - 80px);
}

.react-player > video {
  border-radius: 17px;
}

[class*="text-Heading1"] {
  font-weight: 700 !important;
}
[class*="text-Heading2"] {
  font-weight: 700 !important;
}
[class*="text-Heading3"] {
  font-weight: 450 !important;
}
[class*="text-Body"] {
  font-weight: 450 !important;
}
[class*="text-Body-Bold"] {
  font-weight: 700 !important;
}
[class*="text-Caption"] {
  font-weight: 450 !important;
}

/* Modal */
.drawer {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: table;
  transform: scale(0);
}
.drawer-overlay {
  display: table-cell;
  background-color: rgba(0, 0, 0, 0.6);
  text-align: center;
  vertical-align: bottom;
}
.drawer-content {
  width: 100vw;
  display: inline-block;
  position: relative;
  text-align: left;
}

.modal {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: table;
  transform: scale(0);
}
.modal-overlay {
  display: table-cell;
  background-color: rgba(0, 0, 0, 0.6);
  text-align: center;
  vertical-align: middle;
}
.modal-overlay.hidden {
  background-color: transparent !important;
}
.modal-content {
  display: inline-block;
  position: relative;
  text-align: left;
}
.modal-overlay.left {
  text-align: left;
}
.modal-overlay.right {
  text-align: right;
}
.modal-overlay.top {
  vertical-align: top;
}
.modal-overlay.bottom {
  vertical-align: bottom;
}

.modal.fade.active,
.drawer.active {
  transform: scale(1);
}
.modal.fade.active .modal-overlay,
.drawer.active .drawer-overlay {
  background-color: rgba(0, 0, 0, 0);
  animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.modal.fade.active .modal-content,
.drawer.active .drawer-content {
  opacity: 0;
  animation: scaleUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.modal.fade.active.hide,
.drawer.active.hide {
  animation: quickScaleDown 0s 0.5s linear forwards;
}

.modal.fade.active.hide .modal-overlay,
.drawer.active.hide .drawer-overlay {
  animation: fadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.modal.fade.active.hide .modal-content,
.drawer.active.hide .drawer-content {
  animation: scaleDown 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.modal.unfold.active {
  transform: scaleY(0.01) scaleX(0);
  animation: unfoldIn 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
.modal.unfold.active.hide {
  transform: scale(1);
  animation: unfoldOut 1s 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
.modal.unfold.active .modal-content {
  transform: scale(0);
  animation: zoomIn 0.5s 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
.modal.unfold.active.hide .modal-content {
  animation: zoomOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.modal.runner.active {
  transform: scale(1);
}
.modal.runner.active.hide {
  animation: quickScaleDown 0s 0.5s linear forwards;
}
.modal.runner.active .modal-overlay {
  background: rgba(0, 0, 0, 0);
  animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
.modal.runner.active.hide .modal-overlay {
  animation: fadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
.modal.runner.active .modal-content {
  transform: translateX(-1500px);
  animation: roadRunnerIn 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
.modal.runner.active.hide .modal-content {
  animation: roadRunnerOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.modal.pulse.active {
  transform: scale(1);
}
.modal.pulse.active.hide {
  animation: quickScaleDown 0s 0.5s linear forwards;
}
.modal.pulse.active .modal-overlay {
  background: rgba(0, 0, 0, 0);
  animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
.modal.pulse.active.hide .modal-overlay {
  animation: fadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
.modal.pulse.active .modal-content {
  animation: pulseUp 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
.modal.pulse.active.hide .modal-content {
  animation: pulseDown 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.modal.spin.active {
  transform: scale(1);
}
.modal.spin.active.hide {
  animation: quickScaleDown 0s 0.5s linear forwards;
}
.modal.spin.active .modal-overlay {
  background: rgba(0, 0, 0, 0);
  animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
.modal.spin.active.hide .modal-overlay {
  animation: fadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
.modal.spin.active .modal-content {
  animation: spinIn 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
.modal.spin.active.hide .modal-content {
  animation: spinOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.new-notification {
  transform: scale(0);
  width: 100%;
}
.new-notification.show {
  animation: pulseUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  animation-delay: 0.2s;
}

.custom-notification {
  transform: scale(0);
  width: 100%;
  margin-bottom: 0.5rem;
}

.custom-notification.active {
  transform: scale(1);
}

.custom-notification.active.show {
  animation: pulseUp 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.custom-notification.active.hide {
  animation: pulseDownTwo 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

@keyframes unfoldIn {
  0% {
    transform: scaleY(0.005) scaleX(0);
  }
  50% {
    transform: scaleY(0.005) scaleX(1);
  }
  100% {
    transform: scaleY(1) scaleX(1);
  }
}

@keyframes unfoldOut {
  0% {
    transform: scaleY(1) scaleX(1);
  }
  50% {
    transform: scaleY(0.005) scaleX(1);
  }
  100% {
    transform: scaleY(0.005) scaleX(0);
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes zoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes fadeIn {
  0% {
    background: rgba(0, 0, 0, 0);
  }
  100% {
    background: rgba(0, 0, 0, 0.7);
  }
}

@keyframes fadeOut {
  0% {
    background: rgba(0, 0, 0, 0.7);
  }
  100% {
    background: rgba(0, 0, 0, 0);
  }
}

@keyframes scaleUp {
  0% {
    transform: scale(0.8) translateY(1000px);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
}

@keyframes scaleDown {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
  100% {
    transform: scale(0.8) translateY(1000px);
    opacity: 0;
  }
}

@keyframes quickScaleDown {
  0% {
    transform: scale(1);
  }
  99.9% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes roadRunnerIn {
  0% {
    transform: translateX(-1500px) skewX(30deg) scaleX(1.3);
  }
  70% {
    transform: translateX(30px) skewX(0deg) scaleX(0.9);
  }
  100% {
    transform: translateX(0px) skewX(0deg) scaleX(1);
  }
}

@keyframes roadRunnerOut {
  0% {
    transform: translateX(0px) skewX(0deg) scaleX(1);
  }
  30% {
    transform: translateX(-30px) skewX(-5deg) scaleX(0.9);
  }
  100% {
    transform: translateX(1500px) skewX(30deg) scaleX(1.3);
  }
}

@keyframes pulseUp {
  0% {
    transform: scale(0.6);
    opacity: 0;
  }
  40% {
    transform: scale(0.6);
    opacity: 0.4;
  }
  70% {
    transform: scale(1.2);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes pulseDown {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  30% {
    transform: scale(1.2);
    opacity: 0.8;
  }
  70% {
    transform: scale(0.6);
    opacity: 0.4;
  }
  100% {
    transform: scale(0.6);
    opacity: 0;
  }
}

@keyframes pulseDownTwo {
  0% {
    transform: scale(1);
    opacity: 1;
    max-height: 120px;
    margin-bottom: 0.5rem;
  }

  100% {
    transform: scale(0.6);
    opacity: 0;
    max-height: 0px;
    margin-bottom: 0rem;
  }
}

@keyframes spinIn {
  0% {
    transform: scale(0.6) rotate(0deg);
    opacity: 0;
  }
  100% {
    transform: scale(1) rotate(360deg);
    opacity: 1;
  }
}

@keyframes spinOut {
  0% {
    transform: scale(1) rotate(360deg);
    opacity: 1;
  }
  100% {
    transform: scale(0.8) rotate(0deg);
    opacity: 0;
  }
}
